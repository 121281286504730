export const apiBaseURL = "/api/sitecore/";
export const gtmId = "GTM-TRTQFK";
export const myPageURL = "https://mypage.commufa.jp";
export const jQueryScriptURL = "https://code.jquery.com/jquery-3.5.1.min.js";

// TODO: Update correct URL of pages
// local
// export const cpadPageURL = "https://cpadjss.dev.local";
// export const mycommufaPageURL = "https://mycommufajss.dev.local";
// export const masterPageURL = "https://masterjss.dev.local";
// export const commufaURL = "https://commufa.dev.local";
// export const domain = ".dev.local";
// export const postConversionAzureFuncURL = "https://masterjss.dev.local/api/RestoreApplicationData";

// DEV
//export const cpadPageURL = "https://c-pad.dev.cms.commufa.jp"
//export const mycommufaPageURL = "https://mycommufa.dev.cms.commufa.jp"
//export const masterPageURL = "https://master.dev.cms.commufa.jp"
//export const commufaURL = "https://www.dev.cms.commufa.jp";
//export const domain = ".dev.cms.commufa.jp";
//export const postConversionAzureFuncURL = "https://functions.dev.cms.commufa.jp/api/RestoreApplicationData";

// STG
// export const cpadPageURL = "https://c-pad.stg.cms.commufa.jp"
// export const mycommufaPageURL = "https://mycommufastg.cms.commufa.jp"
// export const masterPageURL = "https://master.stg.cms.commufa.jp"
// export const commufaURL = "https://www.stg.cms.commufa.jp";
// export const domain = ".stg.cms.commufa.jp";
// export const postConversionAzureFuncURL = "https://functions.stg.cms.commufa.jp/api/RestoreApplicationData";

// PROD
export const cpadPageURL = "https://c-pad.commufa.jp"
export const mycommufaPageURL = "https://mycommufa.commufa.jp"
export const masterPageURL = "https://master.commufa.jp"
export const commufaURL = "https://www.commufa.jp";
export const domain = ".commufa.jp";
export const postConversionAzureFuncURL = "https://functions.cms.commufa.jp/api/RestoreApplicationData";

// Token Script
export const tokenScriptURL =
"https://www3n.yds-net.ne.jp/CANDY-W-DEMO/js/token.js";

// Sitecore Setting Path
export const cpadSiteSetting = "/sitecore/content/jss/cpadjss";
export const masterSiteSetting = "/sitecore/content/jss/masterjss";
export const myCommufaSiteSetting = "/sitecore/content/jss/mycommufajss";
export const commonSetting = "/Settings/Common Settings";

//Recaptcha
//TODO: This is just a sample sitekey for testing, need to replace in the future.
export const siteKey = "6LcuRNopAAAAAH5OLsDyG_h-YWTO-5rzE_9pjtMm";